import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  forwardRef,
} from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
// Components
import Navbar from "../components/NavBar"
import Footer from "../components/Footer"
// Assets
import jumboFragment1 from "../assets/wheres-jellow/jumbo-fragment-1.png"
import jumboFragment2 from "../assets/wheres-jellow/jumbo-fragment-2.png"
import jumboFragment3 from "../assets/wheres-jellow/jumbo-fragment-3.png"
import jumboFragment4 from "../assets/wheres-jellow/jumbo-indigo.png"
import jumboFragment5 from "../assets/wheres-jellow/jumbo-purple.png"
import jumboFragment6 from "../assets/wheres-jellow/jumbo-teal.png"
import headingFragment1 from "../assets/wheres-jellow/heading-fragment-01.png"
import headingFragment2 from "../assets/wheres-jellow/heading-fragment-02.png"
import jumboJellow from "../assets/wheres-jellow/Original.png"

function WheresJellow() {
  gsap.registerPlugin(ScrollTrigger)
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
      linesBG: file(relativePath: { eq: "lines-bg.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      jumboJellow: file(relativePath: { eq: "jumbo-jellow.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const tokenProbabilityData = [
    {
      tier: "1 - 99",
      cost: "50",
      low: "0.01125%",
      high: "0.01138%",
    },
    {
      tier: "100 - 299",
      cost: "150",
      low: "0.01138%",
      high: "0.16949%",
    },
    {
      tier: "300 - 999",
      cost: "300",
      low: "0.01164%",
      high: "0.01267%",
    },
    {
      tier: "1,000 - 2,000",
      cost: "350",
      low: "0.01268%",
      high: "0.01452%",
    },
    {
      tier: "2,001 - 3,000",
      cost: "450",
      low: "0.01452%",
      high: "0.01698%",
    },
    {
      tier: "3,001 - 4,000",
      cost: "500",
      low: "0.01699%",
      high: "0.02045%",
    },
    {
      tier: "4,001 - 5,000",
      cost: "600",
      low: "0.02046%",
      high: "0.02571%",
    },
    {
      tier: "5,001 - 6,000",
      cost: "750",
      low: "0.02573%",
      high: "0.03461%",
    },
    {
      tier: "6,001 - 7,000",
      cost: "800",
      low: "0.03464%",
      high: "0.05294%",
    },
    {
      tier: "7,001 - 8,000",
      cost: "850",
      low: "0.05299%",
      high: "0.11249%",
    },
    {
      tier: "8,001 - 8,200",
      cost: "900",
      low: "0.11274%",
      high: "0.14514%",
    },
    {
      tier: "8,201 - 8,400",
      cost: "950",
      low: "0.14556%",
      high: "0.20450%",
    },
    {
      tier: "8,401 - 8,500",
      cost: "1,000",
      low: "0.20534%",
      high: "0.25707%",
    },
    {
      tier: "8,501 - 8,600",
      cost: "1,050",
      low: "0.25840%",
      high: "0.34602%",
    },
    {
      tier: "8,601 - 8,700",
      cost: "1,100",
      low: "0.34843%",
      high: "0.52910%",
    },
    {
      tier: "8,701 - 8,750",
      cost: "1,150",
      low: "0.53476%",
      high: "0.72464%",
    },
    {
      tier: "8,751 - 8,800",
      cost: "1,200",
      low: "0.72993%",
      high: "1.12360%",
    },
    {
      tier: "8,801 - 8,850",
      cost: "1,300",
      low: "1.14943%",
      high: "2.56410%",
    },
    {
      tier: "8,851 - 8,860",
      cost: "1,350",
      low: "2.70270%",
      high: "3.57143%",
    },
    {
      tier: "8,861 - 8,870",
      cost: "1,400",
      low: "3.70370%",
      high: "5.26316%",
    },
    {
      tier: "8,871 - 8,880",
      cost: "1,450",
      low: "5.88235%",
      high: "11.11111%",
    },
    {
      tier: "8,881 - 8,888",
      cost: "1,500",
      low: "14.28571%",
      high: "100.00000%",
    },
  ]

  const pageRef = useRef()
  const jellowRef = useRef()

  // Tracks user scroll
  const [scrolling, setScrolling] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)
  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop)
      setScrolling(e.target.documentElement.scrollTop > scrollTop)
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  // Adds each section to ref array
  const [sectionRefState, setSectionRefState] = useState([])
  const sectionRefs = useRef()
  const tabRefs = useRef({})
  sectionRefs.current = []
  const addtoRefs = el => {
    if (el && !sectionRefs.current.includes(el)) {
      sectionRefs.current.push(el)
      if (el && !sectionRefState.includes(el)) {
        setSectionRefState([el, ...sectionRefState])
      }
    }
  }
  // Handle scroll to section on tab click
  const scrollTo = ele => {
    ele.scrollIntoView({
      alignToTop: true,
      behavior: "smooth",
      block: "start",
    })
  }

  // Animation sections fade in
  useLayoutEffect(() => {
    for (let i = 0; i < sectionRefs.current.length; i++) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: sectionRefs.current[i],
          start: "top center",
          end: "top top+=150",
          scrub: 1,
          id: `section ${i}`,
          toggleActions: "play reset play reset",
          markers: false,
        },
      })
      tl.fromTo(
        sectionRefs.current[i].querySelector(".section-heading"),
        1,
        {
          autoAlpha: 0,
          y: 100,
        },
        {
          autoAlpha: 1,
          y: 0,
          ease: "power4",
        },
        "-=1"
      )
      tl.fromTo(
        sectionRefs.current[i].querySelector(".section-body"),
        1,
        {
          autoAlpha: 0,
          y: 150,
        },
        {
          autoAlpha: 1,
          y: 0,
          ease: "power4",
        },
        "-=1"
      )
      tl.fromTo(
        sectionRefs.current[i].querySelector(".section-image"),
        1,
        {
          autoAlpha: 0,
          y: 200,
        },
        {
          autoAlpha: 1,
          y: 0,
          ease: "power4",
        },
        "-=1"
      )
      gsap.fromTo(
        tabRefs.current[i],
        {
          backgroundColor: "rgb(100 116 139)",
        },
        {
          backgroundColor: "rgb(20 184 166)",
          duration: 0.2,
          ease: "power4",
          scrollTrigger: {
            trigger: sectionRefs.current[i],
            start: "top center",
            end: "bottom center",
            toggleActions: "play reverse play reset",
            id: `tab-${i}`,
            markers: false,
          },
        }
      )
    }
  }, [sectionRefState, tabRefs])

  // Animate big jellow on mobile
  useLayoutEffect(() => {
    const element = jellowRef.current

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: "top top+=64",
        end: "bottom center",
        pin: true,
        pinSpacing: false,
        scrub: 1,
        markers: false,
        id: "jellow",
      },
    })
    tl.to(element, {
      y: -100,
      autoAlpha: 0,
    })
  }, [jellowRef])

  return (
    <div className="relative lg:min-h-screen" ref={pageRef}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.site.siteMetadata.title} | Wheres Jellow</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Navbar sticky={true} />
      <div className="z-20 mx-auto grid min-h-full grid-flow-col grid-cols-12 overflow-x-hidden px-4">
        <div className="col-span-11 col-start-1 row-start-1 flex min-h-full flex-col lg:col-span-6 lg:col-start-2">
          <Section1 ref={{ addtoRefs, jellowRef }} />
          <Section2 ref={addtoRefs} />
          <Section3 ref={addtoRefs} data={tokenProbabilityData} />
          <Section4 ref={addtoRefs} />
          <Section5 ref={addtoRefs} />
          <Section6 ref={addtoRefs} />
        </div>
        <div className="relative col-span-1 col-start-12 row-start-1 flex min-h-screen flex-col items-end justify-center lg:col-start-1 lg:items-center">
          <div className="fixed top-0 flex h-screen flex-col justify-center gap-2">
            {sectionRefState.map((el, i) => {
              return (
                <button
                  ref={element => {
                    tabRefs.current[i] = element
                  }}
                  type="button"
                  key={i}
                  className={`block h-6 w-1 rounded-full bg-slate-500 transition-all ease-in-out lg:h-12 lg:w-[6px]`}
                  onClick={() => {
                    scrollTo(el)
                  }}
                ></button>
              )
            })}
          </div>
        </div>
      </div>
      <JumboSideJellow />
      <Footer />
    </div>
  )
}

const Section1 = forwardRef((props, ref) => {
  const { addtoRefs, jellowRef } = ref
  return (
    <div
      className="relative flex min-h-screen flex-col py-24 md:justify-center lg:py-12"
      ref={addtoRefs}
    >
      <div className="relative mb-12 w-fit">
        <img
          className="absolute -top-8 -left-4 scale-50 lg:-top-8 lg:-left-12 lg:scale-100"
          src={headingFragment2}
          alt="heading fragment 2"
        />
        <h1 className="custom-font stroke text-2xl leading-relaxed text-white drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:text-5xl lg:leading-[4.5rem]">
          Where’s Jellowo?
        </h1>
        <img
          className="absolute -bottom-6 -right-8 scale-50 lg:-bottom-0 lg:-right-16 lg:scale-100"
          src={headingFragment1}
          alt="heading fragment 1"
        />
      </div>
      <p className="custom-font text-base leading-loose lg:text-lg">
        Are You Ready To Participate In The Most Intricate Jellowo Scavenger
        Hunt And Put Yourself Into The Ultimate Test Of Risk and Luck?
        Introducing “Where’s Jellowo?” - Our First-Ever Play-To-Earn Rewards
        Game For All Jellowo Holders. Here’s How It Works…
      </p>
      <div
        className="absolute top-0 left-0 right-0 -z-10 ml-auto mr-auto h-screen w-screen lg:hidden"
        ref={jellowRef}
      >
        <img
          className="absolute -bottom-12 right-0 -z-10 origin-bottom-right scale-50"
          src={jumboFragment5}
          alt="jumbo fragment 5"
        />
        <img
          className="absolute -bottom-14 -right-8 -z-10 origin-bottom-right scale-50"
          src={jumboFragment4}
          alt="jumbo fragment 4"
        />
        <img
          className="absolute -bottom-4 -right-12 -z-10 origin-bottom-right scale-50"
          src={jumboJellow}
          alt="jumbo jellow"
        />
        <img
          className="absolute bottom-8 right-40 -z-10 scale-50"
          src={jumboFragment3}
          alt="jumbo fragment 3"
        />
        <img
          className="absolute bottom-16 right-32 -z-10 scale-50"
          src={jumboFragment2}
          alt="jumbo fragment 2"
        />
        <img
          className="absolute bottom-52 right-0 -z-10 scale-50"
          src={jumboFragment1}
          alt="jumbo fragment 1"
        />
      </div>
    </div>
  )
})

const Section2 = forwardRef((props, ref) => {
  return (
    <div
      className="flex min-h-screen flex-col justify-center py-24 lg:py-12"
      ref={ref}
    >
      <div className="section-heading">
        <h2 className="custom-font stroke mb-8 text-2xl leading-relaxed text-white drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:mb-12 lg:text-5xl lg:leading-[4.5rem]">
          Setting The Stage
        </h2>
      </div>
      <div className="section-body grid gap-y-4">
        <p className="font-poppins text-base leading-loose lg:text-lg">
          Each month, our OG Jellowo - Pat the artist, will be generating a
          Jellowo canvas art of 8,888 puzzle pieces. Each puzzle piece will be
          randomly stored inside a diamond chest NFT that’s airdropped to each
          Jellowo holder’s wallet address for free! Out of the 8,888 puzzle
          pieces, only 1 of them will contain a special Jellowo that can claim
          the ultimate game prize of 4 ETH!
        </p>
        <p className="font-poppins text-base leading-loose lg:text-lg">
          As a holder of our Jellowo NFT, you will also be eligible to receive
          our weekly loot box(es), depending on how many NFTs you own. Inside
          our loot box, you will be distributed with our $JELLO tokens and
          whitelist allocations.
        </p>
        <div className="relative my-4 border border-slate-100 p-4">
          <StaticImage
            src="../assets/wheres-jellow/Jellow_Main_Canvas.png"
            alt="canvas"
            className="w-full"
            objectFit="cover"
            placeholder="none"
            layout="constrained"
          />
          <StaticImage
            src="../assets/wheres-jellow/Special_Jellow_Piece.png"
            alt="special piece"
            className="!absolute -right-4 -bottom-4"
            objectFit="contain"
            height={150}
            placeholder="none"
            layout="constrained"
          />
        </div>
      </div>
    </div>
  )
})

const Section3 = forwardRef((props, ref) => {
  return (
    <div
      className="flex min-h-screen flex-col justify-center py-24 lg:py-12"
      ref={ref}
    >
      <div className="section-heading">
        <h2 className="custom-font stroke mb-8 text-2xl leading-relaxed text-white drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:mb-12 lg:text-5xl lg:leading-[4.5rem]">
          The Rules
        </h2>
      </div>
      <div className="section-body">
        <p className="mb-8 font-poppins text-base leading-loose lg:mb-12 lg:text-lg">
          In order to open a loot box and reveal what the puzzle piece is, it
          will require a minimum of 50 $JELLO tokens. The $JELLO tokens will be
          distributed to you weekly as a holder of a Jellowo NFT via our loot
          box. The caveat is, the longer you wait to open a diamond chest, the
          more $JELLO tokens it will require. However, your chances of winning
          the special Jellowo puzzle piece will also increase at the same time!
        </p>
        <div className="flex flex-col gap-4 rounded-xl border-4 border-black bg-[#819EFA] p-2 text-xs drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:p-4 lg:text-sm">
          <div className="grid grid-cols-4 font-bold">
            <div className="p-2">
              <p>Tiers</p>
            </div>
            <div className="p-2">
              <p>$JELLO Costs</p>
            </div>
            <div className="p-2 text-right">
              <p>Winning Probability (Low)</p>
            </div>
            <div className="p-2 text-right">
              <p>Winning Probability (High)</p>
            </div>
          </div>
          <div className="flex flex-col overflow-hidden rounded-lg bg-white">
            {props.data.map((data, i) => {
              return (
                <div
                  key={i}
                  className={`grid grid-cols-4 ${
                    i % 2 ? "bg-[#F5F5F5]" : null
                  }`}
                >
                  <div className="p-2 font-bold">
                    <p>{data.tier}</p>
                  </div>
                  <div className="p-2">
                    <p>{data.cost}</p>
                  </div>
                  <div className="p-2 text-right">
                    <p>{data.low}</p>
                  </div>
                  <div className="p-2 text-right">
                    <p>{data.high}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
})

const Section4 = forwardRef((props, ref) => {
  return (
    <div
      className="flex min-h-screen flex-col justify-center gap-y-8 py-24 lg:gap-y-12 lg:py-12"
      ref={ref}
    >
      <div className="section-heading">
        <h2 className="custom-font stroke text-2xl leading-relaxed text-white drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:text-5xl lg:leading-[4.5rem]">
          The Plot Twist
        </h2>
      </div>
      <div className="section-body">
        <p className="font-poppins text-base leading-loose lg:text-lg">
          In this game, you have full control of your fate. Every decision you
          make will either increase or decrease your chances of winning the
          special Jellowo puzzle piece. First of all, you may decide whether or
          not to open a diamond chest, and if you do, you can decide when to
          open it. Second, if you decide against opening a diamond chest, you
          can trade that diamond chest in a dedicated OpenSea collection. This
          means players in the game can buy and sell each other diamond chest,
          and luck may be exchanged along the way.
        </p>
      </div>
      <div className="section-image">
        <StaticImage
          src="../assets/wheres-jellow/loot-card-desktop.png"
          alt="Loot"
          className="!hidden max-w-2xl md:!block"
          objectFit="contain"
          placeholder="none"
          layout="constrained"
        />
        <StaticImage
          src="../assets/wheres-jellow/loot-card-mobile.png"
          alt="Loot"
          className="max-w-2xl md:!hidden"
          objectFit="contain"
          placeholder="none"
          layout="constrained"
        />
      </div>
    </div>
  )
})

const Section5 = forwardRef((props, ref) => {
  return (
    <div
      className="flex min-h-screen flex-col justify-center gap-y-8 py-24 lg:gap-y-12 lg:py-12"
      ref={ref}
    >
      <div className="section-heading">
        <h2 className="custom-font stroke text-2xl leading-relaxed text-white drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:text-5xl lg:leading-[4.5rem]">
          The Result
        </h2>
      </div>
      <div className="section-body">
        <p className="font-poppins text-base leading-loose lg:text-lg">
          When a Jellowo holder reveals the special Jellowo puzzle piece, the
          holder wins the game and claims the prize of 4 ETH! BUT - that’s not
          the end. There will be a grace period of 7 days after the winner
          claims the prize for all other players to open any remaining diamond
          chests for just 1 $JELLO token. Each time a remaining diamond chest is
          opened, it will serve as one entry to our raffle draw. The lucky
          winner of the raffle will win 50% of all the tokens used to open the
          remaining tokens! This means even if you’re not the winner of “Where’s
          Jellowo?”, you will still have a chance to win the raffle!
        </p>
      </div>
      <div className="section-image">
        <div className="!hidden max-w-2xl md:!block">
          <StaticImage
            src="../assets/wheres-jellow/prize-card-desktop.png"
            alt="Loot"
            objectFit="contain"
            placeholder="none"
            layout="constrained"
          />
        </div>
        <div className="max-w-2xl md:!hidden">
          <StaticImage
            src="../assets/wheres-jellow/prize-card-mobile.png"
            alt="Loot"
            objectFit="contain"
            placeholder="none"
            layout="constrained"
          />
        </div>
      </div>
    </div>
  )
})

const Section6 = forwardRef((props, ref) => {
  return (
    <div
      className="flex min-h-screen flex-col justify-center gap-6 py-24 lg:py-12 font-poppins"
      ref={ref}
    >
      <div className="section-heading">
        <h2 className="custom-font stroke mb-8 text-2xl leading-relaxed text-white drop-shadow-[-4px_4px_0_rgba(0,0,0,1)] lg:mb-12 lg:text-5xl lg:leading-[4.5rem]">
          The Questions
        </h2>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4 rounded-xl border-[6px] border-black bg-white p-4 drop-shadow-[-4px_4px_0_rgba(0,0,0,0.25)]">
          <p className="font-bold text-purple-700">
            How many diamond chests will each Jellowo holder get?
          </p>
          <p>
            For every Jellowo NFT you have, you will be airdropped one diamond
            chest NFT for free into your MetaMask wallet.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4 rounded-xl border-[6px] border-black bg-white p-4 drop-shadow-[-4px_4px_0_rgba(0,0,0,0.25)]">
          <p className="font-bold text-purple-700">
            What is the difference between the loot box and diamond chest NFT?
          </p>
          <p>
            Loot boxes are distributed weekly which contain $JELLO tokens and
            whitelist allocations for our holders. Diamond chests is an NFT
            that’s airdropped to the holder's wallet to participate in our
            “Where’s Jellowo?” game for a chance to win the grand prize.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4 rounded-xl border-[6px] border-black bg-white p-4 drop-shadow-[-4px_4px_0_rgba(0,0,0,0.25)]">
          <p className="font-bold text-purple-700">
            What if no one reveals the special Jellowo puzzle piece?
          </p>
          <p>
            The scavenger hunt will continue until the special Jellowo puzzle
            piece is revealed and the prize is claimed. Until then, you may buy
            diamond chests from OpenSea for your chance to win the scavenger
            hunt.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4 rounded-xl border-[6px] border-black bg-white p-4 drop-shadow-[-4px_4px_0_rgba(0,0,0,0.25)]">
          <p className="font-bold text-purple-700">
            Can a non-Jellowo NFT holder play Where’s Jellowo?
          </p>
          <p>
            Yes, non-Jellowo NFT holders can play Where’s Jellowo by purchasing
            diamond chests from OpeaSea and $JELLO Tokens. However, it will
            require more $JELLO tokens for non-Jellowo NFT holders to open up
            the diamond chests.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4 rounded-xl border-[6px] border-black bg-white p-4 drop-shadow-[-4px_4px_0_rgba(0,0,0,0.25)]">
          <p className="font-bold text-purple-700">
            How do I claim the loot box(es) and diamond chest(s)?
          </p>
          <p>
            Go to our Where’s Jellowo Reveal page on our website, connect your
            MetaMask wallet, and any loot box(es) and diamond chest(s) that are
            airdropped to you will be available. You will then decide what to do
            with them.. You may also trade your unopened diamond chest on
            OpenSea in a dedicated collection.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4 rounded-xl border-[6px] border-black bg-white p-4 drop-shadow-[-4px_4px_0_rgba(0,0,0,0.25)]">
          <p className="font-bold text-purple-700">
            How will I know what the special Jellowo puzzle piece looks like?
          </p>
          <p>
            We will be revealing to you the entire Jellowo Canvas and
            pinpointing the exact Jellowo puzzle piece that you should be
            looking for to win the prize!
          </p>
        </div>
      </div>
    </div>
  )
})

const JumboSideJellow = () => {
  return (
    <div className="fixed top-0 right-0 z-10 mr-auto hidden h-screen lg:block lg:w-1/3 2xl:w-1/2">
      <img
        className="!absolute bottom-0 -right-[20%] top-0 -z-10 h-[105%] object-cover object-left-bottom"
        src={jumboFragment6}
        alt="jumbo fragment 6"
      />
      <img
        className="!absolute -bottom-36 -right-12 -z-10 w-full max-w-[820px]"
        src={jumboFragment5}
        alt="jumbo fragment 5"
      />
      <img
        className="!absolute -bottom-40 right-20 -z-10 w-full max-w-[587px]"
        src={jumboFragment4}
        alt="jumbo fragment 4"
      />
      <div className="!absolute bottom-0 right-0">
        <img
          className="relative -bottom-16 -right-12 -z-10 w-full max-w-[563px] origin-bottom-right"
          src={jumboJellow}
          alt="jumbo jellow"
        />
        <img
          className="!absolute bottom-48 -left-28 -z-10 w-full max-w-[50px]"
          src={jumboFragment3}
          alt="jumbo fragment 3"
        />
        <img
          className="!absolute bottom-96 -left-8 -z-10 w-full max-w-[40px]"
          src={jumboFragment2}
          alt="jumbo fragment 2"
        />
        <img
          className="!absolute -top-16 right-12 -z-10 w-full max-w-[60px]"
          src={jumboFragment1}
          alt="jumbo fragment 1"
        />
      </div>
    </div>
  )
}

export default WheresJellow
